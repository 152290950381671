<template>
  <el-table-column v-if="optional" v-bind="attrs">
    <template slot-scope="scope">
      <slot v-bind="scope" />
    </template>
  </el-table-column>
  <el-table-column v-else v-bind="attrs" />
</template>

<script>
import DataTableColumn from "./dataTableColumn.js";
export default DataTableColumn;
</script>

<style lang="scss">
@import "./dataTableColumn.scss";
</style>