export default {
  props: {
    column: {
      // 列名
      type: String,
      default: null
    },
    title: {
      // 列显示文字
      type: String,
      default: null
    },
    optional: {
      // 是否自定义模板
      type: String | Boolean,
      default: false,
      validator(value) {
        return (
          value == "true" || value == "false" || value == true || value == false
        );
      }
    }
  },
  computed: {
    attrs() {
      const attributes = this.$attrs;
      // 设置默认属性：使用 column 作为 el-table-column 的 column-key 和 prop，使用 title 作为 el-table-column 的 label
      if (this.column != null) {
        attributes.prop = this.column;
        attributes.columnKey = this.column;
      }
      if (this.title != null) {
        attributes.label = this.title;
      }
      if (attributes.type === "selection") {
        // 居中复选框
        attributes.align = "center";
      }
      // 默认不换行显示
      attributes.showOverflowTooltip = this.$attrs.showOverflowTooltip || true;
      return attributes;
    }
  }
}